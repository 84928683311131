import { useEffect, useMemo, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { EvaluationStatus, FormRouteType, FormVersion, ParentFormStatusesDto, useFormByEvalId, useParentalFormByEvalId } from 'graphql-api';
import { showToast, ToastType } from 'utils/toast';
import { AccessLevel } from 'redux/authentication/enums';
import NavigationRoute from 'navigation/NavigationRoute';
import { submitAnswerProp } from '../components/EvaluationForm';

import { useAppSelector } from './redux';
import useEvaluation from './useEvaluation';
import useDiscussion from './useDiscussion';
import useRecap from './useRecap';
import useIsFirstRender from './useIsFirstRender';

const useEcec = (id?: string, formVersion = FormVersion.VASU, forcedParent?: boolean) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState<{ [key: string]: number | string }>({});
  const [supportValue, setSupportValue] = useState<string | null>(null);
  const [evaluationSupportValue, setEvaluationSupportValue] = useState<string | null>(null);
  const [participatedSupportValue, setParticipatedSupportValue] = useState<string | null>(null);
  const [error, setError] = useState<boolean>(false);
  const [parentTabStatus, setParentTabStatus] = useState<ParentFormStatusesDto>();
  const { userId, accessLevel } = useAppSelector(({ auth }) => auth);
  const isFirstRender = useIsFirstRender();

  const evaluationId = id ? +id : 0;

  const isRecap = formVersion === FormVersion.RECAP;
  const isDiscussion = formVersion === FormVersion.DISCUSSION;
  const isSupport = accessLevel === AccessLevel.Support;
  const isUnitAdmin = accessLevel === AccessLevel.UnitAdmin;
  const isSupportAndForcedParent = forcedParent && isSupport;
  const isParent = accessLevel === AccessLevel.Parent || isSupportAndForcedParent;

  const vasuFormVersion = isParent && FormVersion.PARENTAL === formVersion ? FormVersion.VASU : formVersion;

  const {
    loading: formLoading,
    data: formData,
    refetch: formRefetch,
  } = useFormByEvalId(evaluationId, vasuFormVersion, {
    notifyOnNetworkStatusChange: true,
  });

  const {
    loading: parentalFormLoading,
    data: parentalFormData,
    refetch: parentalFormRefetch,
  } = useParentalFormByEvalId(evaluationId, FormVersion.PARENTAL, {
    skip: formVersion === FormVersion.DISCUSSION || formVersion === FormVersion.RECAP,
    notifyOnNetworkStatusChange: true,
  });

  const data = useMemo(() => (formVersion === FormVersion.PARENTAL ? parentalFormData : formData), [formData, parentalFormData, formVersion]);
  const refetch = useMemo(() => (formVersion === FormVersion.PARENTAL ? parentalFormRefetch : formRefetch), [formRefetch, parentalFormRefetch, formVersion]);
  const loading = useMemo(() => (formVersion === FormVersion.PARENTAL ? parentalFormLoading : formLoading), [parentalFormLoading, formLoading, formVersion]);
  const formGroup = useMemo(() => (data?.evaluation ? data.evaluation : null), [data]);
  const childName = useMemo(() => (formGroup ? `${formGroup.child.firstName} ${formGroup.child.lastName}` : ''), [formGroup]);
  const childAge = formGroup?.ageOfChildAtEvaluation;
  let selectedRole = accessLevel;
  if (isSupportAndForcedParent) {
    selectedRole = AccessLevel.Parent;
  } else if (isSupport) {
    selectedRole = AccessLevel.Teacher;
  }

  const {
    vasuDisabled,
    sendVasuAnswer,
    setVasuOnLoad,
    answerLoading,
    childFormValue,
    setChildFormValue,
    sendParentSignDiscussionForm,
    sendParentSignRecapForm,
    setHasSavedAnswers,
  } = useEvaluation({
    accessLevel: selectedRole,
    formValue,
    formGroup,
    setFormValue,
    skip: isRecap || isDiscussion,
    refetch,
    formVersion,
    setSupportValue,
    supportValue,
    setEvaluationSupportValue,
    evaluationSupportValue,
    setParticipatedSupportValue,
    participatedSupportValue,
    forcedParent,
  });

  const {
    setDiscussionOnLoad,
    importanceValues,
    setImportanceValues,
    sendDiscussion,
    setAdditionalData,
    discussionDisabled,
    additionalData,
    isDiscussionAnswerLoading,
  } = useDiscussion({
    formValue,
    formGroup,
    setFormValue,
    skip: !isDiscussion,
    refetch,
    formVersion,
    setSupportValue,
    supportValue,
    setEvaluationSupportValue,
    evaluationSupportValue,
    setParticipatedSupportValue,
    participatedSupportValue,
    accessLevel: selectedRole,
  });

  const { sendRecap, setRecapOnLoad, recapFormValue, setRecapFormValue, commentFormValue, setCommentFormValue, isRecapAnswerLoading } = useRecap({
    formGroup,
    setFormValue,
    skip: !isRecap,
    refetch,
    setSupportValue,
    supportValue,
    setEvaluationSupportValue,
    evaluationSupportValue,
    setParticipatedSupportValue,
    participatedSupportValue,
  });

  // SET FORM VALUES FOR DIFFERENT FORM TYPES
  useEffect(() => {
    if (formGroup) {
      if (isDiscussion) {
        setDiscussionOnLoad && setDiscussionOnLoad();
      } else if (isRecap) {
        setRecapOnLoad && setRecapOnLoad();
      } else {
        setVasuOnLoad && setVasuOnLoad();
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formGroup, userId, isRecap, isDiscussion, forcedParent]);

  // NAVIGATION
  const formSigned =
    formGroup?.form?.parentHasSigned &&
    (formGroup?.status === EvaluationStatus.VASU || formGroup?.status === EvaluationStatus.VASU_DONE || formGroup?.status === EvaluationStatus.DISCUSSION_DONE);
  const shouldNavigateParentToParentalForm = formGroup?.status === EvaluationStatus.VASU && formVersion === FormVersion.VASU && isParent && formSigned;
  const shouldNavigateParentToCalendarForm = (formVersion === FormVersion.PARENTAL || formVersion === FormVersion.DISCUSSION) && isParent && formSigned;

  const navigateToParental = () => {
    navigate(`${NavigationRoute.EcecPlan}/${FormRouteType.parentalInvolvement}/${id}`, { replace: true });
  };
  const navigateToCalendar = () => {
    navigate(`${NavigationRoute.EcecPlan}/${FormRouteType.calendar}/${id}`, { replace: true });
  };

  useEffect(() => {
    if (!loading) {
      if (shouldNavigateParentToParentalForm) {
        navigateToParental();
      }
      if (shouldNavigateParentToCalendarForm) {
        navigateToCalendar();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  useEffect(() => {
    if (shouldNavigateParentToParentalForm && isParent) {
      setHasSavedAnswers(false);
    }
  }, [shouldNavigateParentToParentalForm, id, navigate, setHasSavedAnswers, isParent]);

  // DISABLED FORM ELEMENTS, USER CAN SEE THE FORM - BUT ANY ACTION FORBIDDEN
  const disabledForm: boolean = useMemo(() => {
    if (isSupport || isUnitAdmin) {
      return true;
    }
    if (formGroup) {
      if (isDiscussion) {
        return !!(formGroup.form.teacherHasSigned && formGroup.status < EvaluationStatus.RECAP);
      }
      if (isRecap) {
        return !!(formGroup.form.teacherHasSigned && formGroup.status >= EvaluationStatus.RECAP);
      }
      if (formVersion === FormVersion.PARENTAL && formGroup.form.parentHasSigned) {
        return true;
      }
      return !!((isParent ? formGroup.form.parentHasSigned : formGroup.form.teacherHasSigned) && formGroup.status < EvaluationStatus.DISCUSSION);
    }
    return false;
  }, [formGroup, isDiscussion, isParent, isRecap, formVersion, isSupport]);

  const childFormDisabled: boolean = useMemo(
    () => !!formGroup && !!formGroup.form.teacherHasSigned && formGroup.status < EvaluationStatus.DISCUSSION,
    [formGroup],
  );

  // SET DISABLED STATE IF SAVE AND SIGN CLICKED
  const disabled = useMemo(() => (isDiscussion ? discussionDisabled : vasuDisabled), [discussionDisabled, vasuDisabled, isDiscussion]);

  // SEND ANSWERS FOR DIFFERENT FORM TYPES
  const sendAnswers = (sign?: boolean) => {
    if (isDiscussion) {
      sendDiscussion && sendDiscussion(sign);
    } else if (isRecap) {
      sendRecap && sendRecap(sign);
    } else {
      sendVasuAnswer && sendVasuAnswer(sign);
    }
  };

  // SUBMIT ANSWER IF SAVE AND SIGNED, OR ITS UNFINISHED
  const submitAnswer = (data: submitAnswerProp) => {
    data.formEvent?.preventDefault();
    if (data.saveUnfinished) {
      sendAnswers();
    } else {
      if (!disabled) {
        sendAnswers(true);
        if (data.isTeacher && data.isVasu) {
          showToast({
            title: t('common.checkFreeAppointment'),
            type: ToastType.INFO,
          });
        }
      }
      setError(!!disabled);
    }
  };

  // IF CAN'T SAVE AND SIGN - SHOW TOAST MESSAGE
  useEffect(() => {
    if (error) {
      showToast({
        title: t('common.requiredFields'),
        type: ToastType.ERROR,
      });
    }
  }, [error, t]);

  useEffect(() => {
    if (!isFirstRender && !disabled) {
      setError(!!disabled);
    }
  }, [disabled, isFirstRender]);

  // SELECT BOOLEAN BASED FORM VALUES
  const recapOrDiscussionValues = useMemo(() => {
    switch (formVersion) {
      case FormVersion.DISCUSSION:
        return importanceValues;
      case FormVersion.RECAP:
        return recapFormValue;
      default:
        return undefined;
    }
  }, [formVersion, importanceValues, recapFormValue]);

  // SHOW CHILD FORM IF IT'S VASU FORM AND NOT PARENT
  const showChildForm = useMemo(() => !isRecap && !isDiscussion && selectedRole !== AccessLevel.Parent, [isRecap, isDiscussion, selectedRole]);

  const shouldParentFillVasuFirst = useMemo(
    () => isParent && formVersion === FormVersion.PARENTAL && !parentTabStatus?.evaluationForm,
    [isParent, formVersion, parentTabStatus],
  );

  // PARENT FORM SELECTOR
  const formType = useMemo(() => {
    const status = formGroup?.status;
    switch (status) {
      case EvaluationStatus.CREATED:
      case EvaluationStatus.VASU:
      case EvaluationStatus.VASU_DONE:
        return FormRouteType.evaluation;
      case EvaluationStatus.DISCUSSION:
      case EvaluationStatus.DISCUSSION_READY:
      case EvaluationStatus.DISCUSSION_DONE:
        return FormRouteType.discussion;
      case EvaluationStatus.RECAP:
      case EvaluationStatus.RECAP_READY:
      case EvaluationStatus.DONE:
        return FormRouteType.recap;
      default:
        return FormRouteType.evaluation;
    }
  }, [formGroup]);
  const selector: FormRouteType[] = [formType, FormRouteType.parentalInvolvement];
  if (isParent) {
    selector.push(FormRouteType.calendar);
  }
  const selectorData = selector.map((item) => ({ label: t(`formRoutes.${item}`), value: item }));
  const isSelectorVisible = useMemo(() => isParent, [isParent]);

  const isAnyAnswerLoading = answerLoading || isRecapAnswerLoading || isDiscussionAnswerLoading;

  return {
    childName,
    childAge,
    submitAnswer,
    answerLoading: isAnyAnswerLoading,
    sendParentSignDiscussionForm,
    sendParentSignRecapForm,
    loading,
    refetch,
    formGroup,
    childFormValue,
    setChildFormValue,
    error,
    formValue,
    setFormValue,
    disabled,
    showChildForm,
    formVersion,
    recapOrDiscussionValues,
    setRecapFormValue,
    recapFormValue,
    setCommentFormValue,
    commentFormValue,
    setImportanceValues,
    importanceValues,
    setAdditionalData,
    additionalData,
    disabledForm,
    isSelectorVisible,
    selectorData,
    childFormDisabled,
    isParent,
    shouldParentFillVasuFirst,
    setSupportValue,
    supportValue,
    setEvaluationSupportValue,
    evaluationSupportValue,
    setParticipatedSupportValue,
    participatedSupportValue,
    isSupport,
    isUnitAdmin,
    selectedRole,
    setParentTabStatus,
    updatedAt: data?.evaluation.updatedAt,
  };
};

export default useEcec;
