import React, { useEffect, useState } from 'react';

import moment from 'moment';
import type { ChildInfoForm } from 'graphql-api';
import { FormRouteType, FormVersion } from 'graphql-api';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import NavigationRoute from 'navigation/NavigationRoute';
import { AgeGroup, ChildForm, Loader, ParentCalendar, Selector, SupportFormStatus, Switch } from 'components';
import useEcec from 'hooks/useEcec';
import EvaluationForm from 'components/EvaluationForm';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-icon.svg';
import { AccessLevel } from 'redux/authentication/enums';

export interface EcecProps {
  evaluationId?: string;
  formVersion: FormVersion;
  formRouteType?: FormRouteType;
  isArchive?: boolean;
}

const EcecForms: React.FC<EcecProps> = (props) => {
  const { id } = useParams();
  const { evaluationId, formVersion, isArchive, formRouteType } = props;
  const [forcedParent, setForcedParent] = useState(false);
  const [calendarStatusRefresh, setCalendarStatusRefresh] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const {
    childName,
    childAge,
    submitAnswer,
    answerLoading,
    sendParentSignDiscussionForm,
    loading,
    sendParentSignRecapForm,
    refetch,
    formGroup,
    childFormValue,
    setChildFormValue,
    error,
    formValue,
    setFormValue,
    disabled,
    showChildForm,
    setRecapFormValue,
    recapFormValue,
    setCommentFormValue,
    commentFormValue,
    setImportanceValues,
    importanceValues,
    setAdditionalData,
    additionalData,
    disabledForm,
    isSelectorVisible,
    selectorData,
    childFormDisabled,
    isParent,
    shouldParentFillVasuFirst,
    setSupportValue,
    supportValue,
    setEvaluationSupportValue,
    evaluationSupportValue,
    setParticipatedSupportValue,
    participatedSupportValue,
    isSupport,
    isUnitAdmin,
    selectedRole,
    updatedAt,
    setParentTabStatus,
  } = useEcec(evaluationId, formVersion, forcedParent);

  const componentRef = React.useRef(null);

  if (isSupport && selectedRole === AccessLevel.Teacher && formVersion === 'parental_involvement') {
    navigate(`/ecec-plan/${FormRouteType.evaluation}/${id}`, { replace: true });
  }

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <div className="relative contents" ref={componentRef}>
      {!isArchive && <ArrowLeft className="absolute md:-left-10 md:top-2 cursor-pointer print:hidden" onClick={() => navigate(-1)} />}
      {childName && <div className="text-teacher-blue text-xl md:text-5xl font-bold mb-3 md:mb-8 ml-10 md:ml-0 print:ml-0">{childName}</div>}
      {isSupport && (
        <div className="flex items-center justify-around text-xl md:text-5xl font-bold mb-3 md:mb-8 ml-10 md:ml-0 print:ml-0">
          <Switch
            onChange={() => {
              setForcedParent(!forcedParent);
            }}
            checked={forcedParent}
            title={t(`common.parent`)}
          />
          <div className="items-center text-teacher-blue ml-4 text-xs">{moment(updatedAt).format('DD/MM/YYYY')}</div>
          <SupportFormStatus formGroup={formGroup} />
        </div>
      )}
      <Loader isLoading={loading}>
        {formGroup && (
          <>
            <AgeGroup ageGroup={childAge} />
            <div className="print:hidden">
              {showChildForm && childFormValue && setChildFormValue && (
                <ChildForm
                  childFormValue={childFormValue}
                  setChildFormValue={setChildFormValue}
                  dateOfBirth={formGroup?.child.dateOfBirth}
                  refetch={refetch}
                  disabled={childFormDisabled || isArchive || isSupport || isUnitAdmin}
                />
              )}
            </div>
            {!isParent && (
              <div className="hidden print:block">
                <ChildForm
                  childFormValue={formGroup.child as ChildInfoForm}
                  dateOfBirth={formGroup?.child.dateOfBirth}
                  disabled={childFormDisabled || isSupport || isUnitAdmin}
                  editable={false}
                />
              </div>
            )}
            {isSelectorVisible && formRouteType && (
              <div className="print:hidden">
                <Selector
                  data={selectorData}
                  selectedItem={formRouteType}
                  onClick={(item) => navigate(`${NavigationRoute.EcecPlan}/${item}/${evaluationId}`, { replace: true })}
                  evaluationId={evaluationId}
                  status={formGroup.status}
                  calendarStatusRefresh={calendarStatusRefresh}
                  setCalendarStatusRefresh={setCalendarStatusRefresh}
                  setParentTabStatus={setParentTabStatus}
                />
              </div>
            )}
            {!shouldParentFillVasuFirst &&
              (formRouteType !== FormRouteType.calendar ? (
                <EvaluationForm
                  submitAnswer={submitAnswer}
                  formValue={formValue}
                  formGroup={formGroup}
                  disabled={!!disabled || !!isArchive}
                  error={error}
                  answerLoading={!!answerLoading}
                  setFormValue={setFormValue}
                  formType={formVersion}
                  setRecapFormValue={setRecapFormValue}
                  recapFormValue={recapFormValue}
                  setCommentFormValue={setCommentFormValue}
                  commentFormValue={commentFormValue}
                  setImportanceValues={setImportanceValues}
                  importanceValues={importanceValues}
                  setAdditionalData={setAdditionalData}
                  additionalData={additionalData}
                  disabledForm={disabledForm || !!isArchive}
                  setSupportValue={setSupportValue}
                  supportValue={supportValue}
                  setEvaluationSupportValue={setEvaluationSupportValue}
                  evaluationSupportValue={evaluationSupportValue}
                  setParticipatedSupportValue={setParticipatedSupportValue}
                  participatedSupportValue={participatedSupportValue}
                  printRef={componentRef}
                  sendParentSignDiscussionForm={sendParentSignDiscussionForm}
                  sendParentSignRecapForm={sendParentSignRecapForm}
                  isSupport={isSupport}
                  selectedRole={selectedRole}
                />
              ) : (
                <div className="contents">
                  <ParentCalendar
                    parentEmail={'email'}
                    parentId={parseInt(formGroup?.child?.parent?.id)}
                    hideChildComponents={true}
                    defaultChildId={formGroup?.child.id}
                    setCalendarStatusRefresh={setCalendarStatusRefresh}
                    evaluationStatus={formGroup?.status}
                  />
                </div>
              ))}
          </>
        )}
      </Loader>
    </div>
  );
};

export default EcecForms;
