import React from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Ecec, ModalPicker } from 'components';
import { useEvaluationsByChildId } from 'graphql-api/useEvaluationsByChildId';
import { getFormVersionFromStatus } from 'utils/form';

export interface EcecFormsProps {
  childId: string;
}

const EcecForms: React.FC<EcecFormsProps> = (props) => {
  const { childId } = props;
  const { t } = useTranslation();

  const { data } = useEvaluationsByChildId({
    skip: !childId,
    variables: {
      childId,
      archives: true,
    },
  });

  const evalPickerData = React.useMemo(() => {
    return data?.evaluations?.map((evaluation) => ({
      evaluation,
      name: `${moment(evaluation.createdAt).format('yyyy')} - ${evaluation.ageOfChildAtEvaluation} ${t(`ageGroups.${evaluation.ageOfChildAtEvaluation}`)}`,
      slug: `${evaluation.id}`,
    }));
  }, [data, t]);

  const [evalSlug, setEvalSlug] = React.useState<string>();

  const selectedEval = evalPickerData?.find(({ slug }) => slug === evalSlug)?.evaluation;

  return (
    <div className={``}>
      <div className="font-bold text-xl text-teacher-blue mt-3 md:mt-12 mb-3">{t('common.oldForms')}</div>
      <ModalPicker
        data={evalPickerData}
        title={t('yearPicker.yearPlaceholder')}
        label={t('yearPicker.year')}
        selectedItem={evalSlug}
        onChange={setEvalSlug}
        containerClassName="!mb-3 md:!mb-12 flex-1 md:w-72"
      />
      {selectedEval?.id && <Ecec formVersion={getFormVersionFromStatus(selectedEval.status)} evaluationId={`${selectedEval?.id}`} isArchive />}
    </div>
  );
};

export default EcecForms;
