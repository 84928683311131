import { Translation } from 'locales';
import { AccessLevel } from 'redux/authentication/enums';
import { Consultation } from '../hooks/useParentConsultations';

export interface User {
  id: string;
  slug: string;
  role: AccessLevel;
  firstName: string;
  email: string;
  lastName: string;
  unit?: Unit;
  ownedUnits?: Unit[];
  children: Child[];
  groups?: Group[];
  active: string;
}

export interface Unit {
  id: number;
  slug: string;
  name: string;
  city: string;
  addressLine: string;
  zipCode: string;
  canOpenEvaluations: boolean;
  groups: Group[];
  admin?: User;
}

export interface Child {
  id?: string;
  slug: string;
  unit?: Unit;
  firstName: string;
  lastName: string;
  dateOfBirth: Date;
  group?: Group;
  enrollmentDate?: string;
  healthAndOtherReport?: string;
  evaluation?: Evaluation;
  parent: User;
  active: string;
}

export interface FAQItem {
  question: Translation;
  answer: Translation;
}

export interface FAQVideo {
  id: string;
  url: string;
}

export interface LOGItem {
  id: number;
  actionName: string;
  entityName: string;
  info: string;
  createdAt: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
  };
}

export interface EvaluationGroupStatus {
  ecec: number;
  discussion: number;
  recap: number;
  done: number;
  status: ChildrenEvalutationStatus;
}

export interface Group {
  id: string;
  slug: string;
  name: string;
  unitId: number;
  children: Child[];
  evaluationStatus: EvaluationGroupStatus;
}

export type UnitAndGroup = Unit & { groups: Group[] };

export enum ChildrenEvalutationStatus {
  none = 'none',
  inProgress = 'inProgress',
  done = 'done',
}

export enum ChildrenEvalutationType {
  ece = 'ece',
  discussion = 'discussion',
  recap = 'recap',
}

export type ChildById = Child & {
  parent: User;
};

export enum FormVersion {
  VASU = 'vasu',
  PARENTAL = 'parental_involvement',
  DISCUSSION = 'discussion',
  RECAP = 'recap',
}

export enum QuestionType {
  OCCURRENCE = 'occurance',
  IMPORTANCE = 'importance',
  TEXT = 'text',
}

export type AnalyticsType = 'child-relationship' | 'group-results' | 'parental-involvement';

export interface AnswerValues {
  repliers: number;
  value: number;
}

export interface AnalyticsAnswer {
  agreed: boolean;
  myAnswer: number;
  values: AnswerValues[];
  discussionAnswer: number;
}

export interface AnalyticsQuestion {
  id: number;
  questionType: QuestionType;
  answers: AnalyticsAnswer;
  question: Translation;
  description?: Translation;
}

export interface AnalyticsForm {
  name: Translation;
  questions: AnalyticsQuestion[];
}

export interface Analytics {
  child?: Child;
  forms: AnalyticsForm[];
}

export enum AnalyticsChartType {
  BAR = 'bar',
  PIE = 'pie',
}

export interface Answer {
  id?: number;
  answer: string | number;
  type: 'default' | 'discussion';
  replier: {
    id: string;
    role: string;
  };
}

export interface Question {
  questionType: QuestionType;
  id: number;
  description?: Translation;
  question: Translation;
  answers: Answer[];
  reached?: boolean;
  isImportantToReach?: boolean;
}

export interface ConsultingEvent {
  id: number;
  startsAt: string;
  endsAt: string;
  teacher?: Pick<User, 'id' | 'firstName' | 'lastName'>;
  type: ConsultationType;
  parent?: {
    id: string;
    firstName: string;
    lastName: string;
  };
  evaluationId: number;
}

export enum EvaluationStatus {
  CREATED,
  VASU,
  VASU_DONE,
  DISCUSSION_READY,
  DISCUSSION,
  DISCUSSION_DONE,
  RECAP_READY,
  RECAP,
  DONE,
}

export interface FormGroups {
  name: Translation;
  id: number;
  questions: Question[];
  changes?: string;
}

export interface Form {
  parentHasSigned?: boolean;
  teacherHasSigned?: boolean;
  teacherSignedName?: string;
  parentSignedName?: string;
  teacherSignedDate?: string;
  parentSignedDate?: string;
  formGroups: FormGroups[];
}

export interface Support {
  ecec: null | string;
  discussion: null | string;
  recap: null | string;
}

export interface Evaluation {
  id: number;
  updatedAt: string;
  createdAt: string;
  status: EvaluationStatus;
  evaluationFormStatus?: string;
  discussionFormStatus?: string;
  recapFormStatus?: string;
  support?: Support;
  evaluationOfSupport?: Support;
  otherParticipated?: Support;
  goal?: string;
  comment?: string;
  child: Child & { parentalInvolvementForm?: { parentHasSigned?: boolean } };
  teacher: User;
  form: Form;
  ageOfChildAtEvaluation?: number;
}

export interface ChildInfoForm {
  id: string;
  enrollmentDate?: string | Date;
  healthAndOtherReport?: string;
}

export enum FormRouteType {
  evaluation = 'evaluation',
  parentalInvolvement = 'parental-evaluation',
  discussion = 'discussion',
  recap = 'recap',
  calendar = 'calendar',
}

export interface CommentsAndGoals {
  comments: string;
  goals: string;
}

export enum DeleteReason {
  CANCEL = 'cancel',
  DONE = 'done',
}

export enum ConsultationType {
  DISCUSSION = 'discussion',
  RECAP = 'recap',
}

export enum EvaluationFormStatus {
  EMPTY = '',
  PARTIALLY_FILLED = 'partially_filled',
  PARTIALLY_FILLED_TEACHER_SIGNED = 'partially_filled_teacher_signed',
  FILLED = 'filled',
}

export interface ParentFormStatusesDto {
  parentalInvolvementForm: boolean;
  evaluationForm: boolean;
  discussionForm: boolean;
  recapForm: boolean;
  bookedAppointment: Consultation[];
}
