import React from 'react';

export type VideoProps = {
  url: string;
};

const Video = ({ url }: VideoProps) => {
  return (
    <iframe
      src={url}
      title="YouTube video player"
      height="400"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen
    />
  );
};

export default Video;
