import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { BreadCrumb, Button, ChildCard, CreateChildModal, EditChildCard, Loader, TextInput, FormStepArchiveButton, LogsList, Switch } from 'components';
import { BreadCrumbData } from 'components/BreadCrumb';
import { useCreateParent, User, useUpdateParent, useDeleteParent, useResendLink, Child } from 'graphql-api';
import { ReactComponent as ArrowLeft } from 'assets/arrow-left-icon.svg';
import { ReactComponent as PlusCircle } from 'assets/plus-circle-icon.svg';
import { ReactComponent as Mail } from 'assets/mail-icon.svg';
import { showToast, ToastType } from 'utils/toast';
import { AccessLevel } from 'redux/authentication/enums';
import { getParentChildren } from 'utils/users';
import { getChildEvaluationStatus } from 'utils/getChildEvaluationStatus';
import { useAppSelector } from 'hooks/redux';

export interface ParentStepProps {
  user?: User;
  breadcrumb: BreadCrumbData[];
  refetch?: () => void;
}

export const ParentStep: React.FC<ParentStepProps> = ({ user, breadcrumb, refetch }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accessLevel } = useAppSelector(({ auth }) => auth);
  const isSupport = accessLevel === AccessLevel.Support;
  const isUnitAdmin = accessLevel === AccessLevel.UnitAdmin;
  const [isChildModalOpen, setIsChildModalOpen] = useState(false);
  const [changedParent, setChangedParent] = useState<User>();
  const [shouldSaveChildren, setShouldSaveChildren] = useState(false);
  const [historyShow, setHistoryShow] = useState(false);

  useEffect(() => {
    setChangedParent(user);
  }, [user]);

  const [createParent, { loading: createLoading }] = useCreateParent({
    onCompleted(createParentData) {
      if (createParentData.createParent.slug) {
        navigate(`/users/${AccessLevel.Parent}/${createParentData.createParent.slug}`);
        showToast({
          title: t('userMaintenanceScreen.user_added'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('userMaintenanceScreen.emailExists'),
        type: ToastType.ERROR,
      });
    },
  });

  const [updateParent, { loading: updateLoading }] = useUpdateParent({
    onCompleted(editParentData) {
      if (editParentData.editParent.id) {
        refetch && refetch();
        showToast({
          title: t('userMaintenanceScreen.user_edited'),
          type: ToastType.SUCCESS,
        });
      }
    },
  });

  const [archiveParent] = useDeleteParent({
    onCompleted(archiveParentData) {
      if (archiveParentData.archiveParent.id) {
        navigate('/users');
        showToast({
          title: t('userMaintenanceScreen.user_deleted'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('userMaintenanceScreen.delete_error'),
        type: ToastType.ERROR,
      });
    },
  });

  const removeUser = () => {
    if (user) {
      archiveParent({ variables: { id: +user.id } });
    }
  };

  const addUser = () => {
    createParent({
      variables: {
        payload: {
          firstName: changedParent?.firstName,
          lastName: changedParent?.lastName,
          email: changedParent?.email,
          children: getParentChildren(changedParent),
        },
      },
    });
  };

  const editUser = () => {
    updateParent({
      variables: {
        id: +(user as User).id,
        payload: {
          firstName: changedParent?.firstName,
          lastName: changedParent?.lastName,
          email: changedParent?.email,
          children: getParentChildren(changedParent),
        },
      },
    });
  };

  const [resendEmailByUserId] = useResendLink({
    onCompleted(resendEmailByUserIdData) {
      if (resendEmailByUserIdData.resendEmailByUserId.message) {
        showToast({
          title: t('userMaintenanceScreen.email_sent'),
          type: ToastType.SUCCESS,
        });
      }
    },
    onError() {
      showToast({
        title: t('common.try_again_later'),
        type: ToastType.ERROR,
      });
    },
  });

  const onChange = (key: keyof User, value: string | Child) => {
    const changedObject = changedParent || ({} as User);
    const renderChildren = () => {
      if (changedObject.children) {
        return changedObject.children.concat(value as Child);
      }
      return [value as Child];
    };
    setChangedParent({
      ...changedObject,
      [key]: key === 'children' ? renderChildren() : value,
    });
  };

  const onRemoveChildClick = (index: number) => {
    const children = (changedParent as User).children.filter((_, filterIndex) => index !== filterIndex);
    setChangedParent((prevState) => ({
      ...(prevState as User),
      children: children,
    }));
  };

  const addChild = (child: Child) => {
    onChange('children', child);

    if (user?.id) {
      setShouldSaveChildren(true);
    }
  };

  useEffect(() => {
    if (shouldSaveChildren) {
      updateParent({
        variables: {
          id: +(user as User).id,
          payload: {
            children: getParentChildren(changedParent),
          },
        },
      });

      setShouldSaveChildren(false);
    }
  }, [shouldSaveChildren, updateParent, changedParent, user]);

  const disableSaveChanges =
    !changedParent?.firstName ||
    !changedParent?.lastName ||
    !changedParent?.email ||
    !changedParent.children ||
    !changedParent.children.length ||
    shouldSaveChildren;
  const childrenIdsFromBackend = user?.children.map((item) => item.id);
  const filteredNewChildren = changedParent?.children ? changedParent?.children.filter((item) => !childrenIdsFromBackend?.includes(item.id)) : [];

  const childCards = user
    ? user?.children.map((child) => {
        const status = getChildEvaluationStatus(child.evaluation?.status);

        return (
          <ChildCard
            key={child.id}
            child={child}
            childrenStatus={status.childrenStatus}
            childrenEvaluationType={status.childrenEvaluationType}
            className="mb-4"
            onEditClick={() => navigate(`/users/${AccessLevel.Parent}/${user.slug}/${child.slug}`)}
          />
        );
      })
    : [];

  return (
    <>
      <div className="flex items-center justify-between relative mb-4">
        <ArrowLeft className="absolute md:-left-10 cursor-pointer" onClick={() => navigate(-1)} />
        <div className="text-teacher-blue text-xl md:text-3xl font-bold ml-10 md:ml-0">
          {!user ? t('userScreen.addNew.Parent') : `${user.firstName} ${user.lastName}`}
        </div>
        {user && !isSupport && !isUnitAdmin && <FormStepArchiveButton onConfirm={removeUser} label={t('userScreen.deleteUser')} />}
        {isSupport && (
          <div className="flex items-center justify-around text-xl md:text-5xl font-bold mb-3 md:mb-8 ml-10 md:ml-0 print:ml-0">
            <Switch
              onChange={() => {
                setHistoryShow(!historyShow);
              }}
              checked={historyShow}
              title={t(`common.history`)}
            />
          </div>
        )}
      </div>
      <BreadCrumb data={breadcrumb} className="mb-4 md:mb-8" />
      <div className="md:grid md:grid-cols-2 md:gap-24">
        <div className="flex flex-col mb-6 md:mb-0">
          <div className="text-teacher-blue text-xl font-bold mb-2">{t('userScreen.parentInfo')}</div>
          <TextInput
            label={t('userScreen.firstName')}
            value={changedParent?.firstName}
            onChange={(e) => onChange('firstName', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <TextInput
            label={t('userScreen.lastName')}
            value={changedParent?.lastName}
            onChange={(e) => onChange('lastName', e.target.value)}
            containerClassName="mb-4"
            disabled={isSupport}
          />
          <TextInput
            label={t('userScreen.email')}
            value={changedParent?.email}
            onChange={(e) => onChange('email', e.target.value)}
            containerClassName="mb-2"
            disabled={isSupport}
          />
          {user && !isSupport && (
            <Button
              variant="tertiary"
              className="w-fit"
              labelClassName="w-full flex justify-center"
              onClick={() => resendEmailByUserId({ variables: { id: user ? +user?.id : undefined } })}
            >
              <Mail className="mr-2" />
              {t('userScreen.resendLink')}
            </Button>
          )}
          <div className="flex items-center justify-between mt-6 gap-6">
            <Button variant="secondary" onClick={() => navigate(-1)}>
              {t('common.cancel')}
            </Button>
            <Button
              variant="primary"
              onClick={() => (user ? editUser() : addUser())}
              icon={<Loader isLoading={updateLoading || createLoading || shouldSaveChildren} isSmall className="mr-2" color="white" />}
              disabled={disableSaveChanges || isSupport}
            >
              {t('common.saveChanges')}
            </Button>
          </div>
        </div>
        <div>
          <div className="flex items-center justify-between mb-4">
            <div className="text-teacher-blue text-xl font-bold">{t('userScreen.children')}</div>
          </div>
          {childCards}
          {filteredNewChildren &&
            filteredNewChildren.map((item, index) => (
              <EditChildCard key={item.firstName + index} child={item} className="mb-4" onRemoveClick={() => onRemoveChildClick(index)} />
            ))}
          <CreateChildModal visible={isChildModalOpen} openModal={setIsChildModalOpen} onSave={addChild} />
          <div className="flex justify-center">
            <Button
              variant="tertiary"
              labelClassName="!text-teacher-blue w-full flex justify-center"
              className="w-fit"
              onClick={() => setIsChildModalOpen(true)}
              disabled={isSupport}
            >
              <PlusCircle className="mr-2" />
              {t('userScreen.createChildProfile')}
            </Button>
          </div>
        </div>
      </div>
      {isSupport && historyShow && <LogsList entityId={parseInt(user?.id as string)} entityName={'user'} />}
    </>
  );
};
