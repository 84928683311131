import React from 'react';

import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { useLogs } from '../../hooks/useLogs';

export interface LogsListProps {
  entityId?: number;
  entityName: string;
}
export interface LogsListInfoType {
  val: any;
  path: string;
  op: string;
}

const LogsList: React.FC<LogsListProps> = ({ entityId, entityName }) => {
  const { t } = useTranslation();
  const { isLoadingLogs, logs } = useLogs(entityId, entityName);

  function isDateValid(dateStr: string) {
    return moment(dateStr).isValid();
  }

  return (
    <>
      {!isLoadingLogs && !!logs.length && (
        <div className={`flex flex-col mt-5`}>
          <div className="text-teacher-blue text-xl font-bold mb-2">History</div>
          {logs.map((log) => (
            <div className="bg-white rounded-lg border-2 p-4 my-2" key={log.id}>
              <div key={`${log.id}-log`}>
                {`${t('userMaintenanceScreen.date_of_modification')}: ${moment(log.createdAt).format('DD/MM/YYYY HH:mm')}, (${log.actionName}) ${t(
                  'userMaintenanceScreen.user',
                )}: ${log.user.firstName} ${log.user.lastName}`}
              </div>
              {!!JSON.parse(log?.info).length && (
                <div key={`${log.id}-info`} className="pt-5 pb-2 text-teacher-blue font-bold">
                  {t('userMaintenanceScreen.changed_state')}
                </div>
              )}
              {JSON.parse(log?.info).map((logData: LogsListInfoType, index: React.Key | null | undefined) => (
                <div key={index}>
                  ({logData?.op}) {logData?.path}:
                  {logData?.val && logData.val !== false
                    ? ` ${typeof logData.val === 'string' && isDateValid(logData.val) ? moment(logData.val).format('DD/MM/YYYY HH:mm') : logData.val}`
                    : ' false'}
                </div>
              ))}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default LogsList;
