import React, { useEffect } from 'react';

import moment from 'moment';
import type { SlotInfo } from 'react-big-calendar';

import { Calendar, ModalPicker, ShowMore } from 'components';
import { ConsultingEvent, DeleteReason, useUserByIdForTeacherCalendar } from 'graphql-api';
import { useCalendarView } from 'hooks/useCalendarView';
import { useConsultations } from 'hooks/useConsultations';

import { useAppSelector } from 'hooks/redux';
import { AccessLevel } from 'redux/authentication/enums';
import { useTranslation } from 'react-i18next';
import { useTeacherByUnitSlug } from '../../hooks/useTeacherByUnitSlug';
import NewTimeModal from './NewTimeModal';
import InformationModal from './InformationModal';

export interface TeacherCalendarProps {
  teacherId: number | null;
  description?: string;
}

export const TeacherCalendar: React.FC<TeacherCalendarProps> = ({ teacherId, description }) => {
  const [newDate, setNewDate] = React.useState(new Date());
  const { accessLevel, userId } = useAppSelector(({ auth }) => auth);
  const isSupport = accessLevel === AccessLevel.Support;
  const isTeacher = accessLevel === AccessLevel.Teacher;
  const { t } = useTranslation();
  const [unitSlug, setUnitSlug] = React.useState<string>();
  const [firstLoad, setFirstLoad] = React.useState<boolean>();
  const [selectedTeacherId, setSelectedTeacherId] = React.useState<number | null>();
  const { teachers, teacherSlug, setTeacherSlug } = useTeacherByUnitSlug(unitSlug);

  const { view, onViewChange } = useCalendarView();

  const onSelectSlot = (selection: SlotInfo) => {
    const now = moment().startOf(view === 'month' ? 'day' : 'hour');

    if (!moment(selection.start).isBefore(now)) {
      setShouldShowNewTimeModal(true);

      const selectedTime = new Date(selection.start);

      if (view === 'month') {
        selectedTime.setHours(11);
      }

      setNewDate(selectedTime);
    }
  };

  const onSelectEvent = (event: ConsultingEvent) => {
    setShouldShowInformationModal(true);
    setSelectedEvent(event);
  };

  const saveTime = (date: string) => {
    createConsultation({
      variables: {
        consultation: {
          startsAt: date,
          teacherID: teacherId,
        },
      },
    });
    setShouldShowNewTimeModal(false);
  };

  const closeInformationModal = () => {
    setShouldShowInformationModal(false);
    setSelectedEvent(undefined);
  };

  const deleteAppointment = () => {
    deleteConsultation({
      variables: {
        consultation: {
          id: selectedEvent?.id,
          reason: DeleteReason.CANCEL,
          timezone: moment.tz.guess(),
        },
      },
    });
  };

  const [shouldShowNewTimeModal, setShouldShowNewTimeModal] = React.useState(false);
  const [shouldShowInformationModal, setShouldShowInformationModal] = React.useState(false);
  const [selectedEvent, setSelectedEvent] = React.useState<ConsultingEvent>();

  const { createConsultation, deleteConsultation, consultations, loading } = useConsultations(selectedTeacherId || null);
  const { data } = useUserByIdForTeacherCalendar(userId, { skip: !2, fetchPolicy: 'no-cache' });

  useEffect(() => {
    if (isTeacher) {
      if (data?.user?.unit?.slug) {
        setUnitSlug(data.user.unit.slug);
      }
    }
  }, [data, isTeacher]);

  useEffect(() => {
    const selected = teachers?.filter((teacher) => teacher.slug === teacherSlug);
    if (selected) {
      setSelectedTeacherId(parseInt(selected[0]?.id));
    }
  }, [teacherSlug, teachers]);

  useEffect(() => {
    if (!firstLoad && teachers?.length) {
      setFirstLoad(true);
      setSelectedTeacherId(teacherId);
      const selected = teachers?.filter((teacher) => parseInt(teacher.id) === teacherId);
      if (selected) {
        setTeacherSlug(selected[0]?.slug);
      }
    }
  }, [firstLoad, setTeacherSlug, teacherId, teachers]);

  return (
    <React.Fragment>
      {description && <ShowMore text={description} className="text-teacher-blue" />}
      {isTeacher && (
        <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-x-5">
          <ModalPicker
            title={t('superAdminCalendar.chooseTeacher')}
            label={t('common.teacher')}
            onChange={(slug) => setTeacherSlug(slug)}
            data={teachers}
            selectedItem={teacherSlug}
          />
        </div>
      )}
      <Calendar
        consultations={loading ? [] : consultations}
        view={view}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onViewChange={onViewChange}
      />

      {shouldShowNewTimeModal && !isSupport && (
        <NewTimeModal
          onClose={() => setShouldShowNewTimeModal(false)}
          visible={shouldShowNewTimeModal}
          saveTime={saveTime}
          date={newDate}
          reservedDates={consultations.map(({ startsAt }) => startsAt)}
          fixHeight={false}
        />
      )}

      <InformationModal
        visible={shouldShowInformationModal && !isSupport}
        date={moment(selectedEvent?.startsAt).toDate()}
        parent={selectedEvent?.parent}
        onClose={closeInformationModal}
        deleteAppointment={deleteAppointment}
        fixHeight={false}
      />
    </React.Fragment>
  );
};
