import { useLogsByEntityId } from 'graphql-api';

export const useLogs = (entityId?: number, entityName?: string) => {
  const { loading, data } = useLogsByEntityId({
    variables: { entityId, entityName },
    fetchPolicy: 'no-cache',
  });

  return {
    isLoadingLogs: loading,
    logs: data?.logs || [],
  };
};
